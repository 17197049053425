import { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

import { buttonStyles, colors } from './style.js'
import './App.css'

const pulsate = keyframes`
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(127,124,254, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 6px rgba(127,124,254, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(127,124,254, 0);
  }
`

const Button = styled.button`
  ${buttonStyles.default};
  margin-bottom: 30px;
`

const ButtonFast = styled.button`
  ${buttonStyles.grey};
`

const Wrapper = styled.div`
  background-color: #3a393d;
  border-radius: 15px;
  color: #f1f1f1;
  width: 300px;
  padding: 20px 25px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Active = styled.div`
  border-radius: 20px;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  background-color: ${colors.purple};
  animation: ${pulsate} 2s infinite;
`

const StepTitle = styled.h2`
  font-size: 18px;
`

const StepWrapper = styled.div`
  margin: 20px 0;
`

const PBWrapper = styled.div`
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  border-radius: 10px;
  position: relative;
`

const PBInner = styled.div.attrs((props) => ({
  style: {
    clipPath: `polygon(0 0, ${props.progress}% 0, ${props.progress}% 100%, 0% 100%)`,
    backgroundColor: props.color ? props.color : 'rgba(255,255,255,0.2)',
  },
}))`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
`

const Label = styled.div`
  font-size: 16px;
  margin: 5px 0;
`

const steps = [
  {
    name: 'Reference server (Hetzner)',
    file: (size) =>
      `https://de1.backend.librespeed.org/garbage.php?cors=true&ckSize=${size}`,
  },
  {
    name: 'Jensen - Node 1',
    file: (size) => `https://node-1.cdn.jensen.nl/speedtest/${size}mb.bin`,
  },
  {
    name: 'Jensen - Node 2',
    file: (size) => `https://node-2.cdn.jensen.nl/speedtest/${size}mb.bin`,
  },
  // {
  //   name: 'Clouvider',
  //   file: (size) =>
  //     `https://ams.speedtest.clouvider.net/backend/garbage.php?cors=true&ckSize=${size}`,
  // },
]

const required = [
  {
    name: '240p',
    bandwidth: 657498,
    color: '#FF6962',
  },
  {
    name: '360p',
    bandwidth: 900144,
    color: '#FF8989',
  },
  {
    name: '480p',
    bandwidth: 1134016,
    color: '#FFDCA4',
  },
  {
    name: '720p',
    bandwidth: 2667594,
    color: '#58D6BF',
  },
  {
    name: '1080p',
    bandwidth: 4465125,
    color: '#7F7CFF',
  },
]

const ProgressBar = (props) => {
  return (
    <PBWrapper>
      <PBInner {...props} />
    </PBWrapper>
  )
}

const dec = (num, deci) => {
  let amount
  let result
  let missing
  let i

  // rounding
  if (deci) {
    amount = Math.pow(10, deci || 1)
    result = Math.round(num * amount) / amount
  } else {
    result = Math.round(num)
  }

  // force trailing zeros
  result = String(result)

  if (!deci) {
    return result
  }

  if (!~result.indexOf('.')) {
    missing = deci
    result += '.'
  } else {
    missing = deci - result.split('.')[1].length
  }

  if (missing) {
    for (i = missing; i > 0; i--) {
      result += '0'
    }
  }

  // done
  return result
}

const Step = ({ active, data, onDone, size }) => {
  const [progress, setProgress] = useState(0)
  const [speed, setSpeed] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const [preset, setPreset] = useState(null)

  useEffect(() => {
    if (active) {
      const decimals = 1
      const start = Date.now()
      const req = new XMLHttpRequest()

      let count = 0
      let sum = 0
      let sumBitrate = 0

      const onProgress = (e) => {
        const now = Date.now()

        let percent = 0.0
        let Bps = 0
        let avg = 0
        let avgBitrate = 0
        let eta = 0

        let total =
          e.lengthComputable && e.total > 0 ? e.total : size * 1024 * 1024

        Bps = e.loaded / ((now - start) / 1000)
        const mbit = (Bps / 1024 / 1024) * 8
        count++
        sum += mbit
        sumBitrate += Bps * 8
        avg = sum / count
        avgBitrate = sumBitrate / count
        percent = (e.loaded / total) * 100.0
        eta = (total - e.loaded) / Bps

        const preset = required.reduce(
          (acc, v) => (v.bandwidth < avgBitrate ? v : acc),
          null
        )

        setPreset(preset)
        setProgress(percent)
        setSpeed(dec(avg, decimals))
        setRemaining(dec(eta, decimals))
      }

      const onReadyStateChange = () => {
        const diff = (Date.now() - start) / 1000

        if (req.readyState !== 4) {
          return
        }

        setRemaining(dec(diff, decimals))
        onDone()
      }

      req.onprogress = onProgress
      req.onreadystatechange = onReadyStateChange

      req.responseType = 'arraybuffer'

      // load file avoiding the cache
      let file = data.file(size)
      req.open(
        'GET',
        file + (file.indexOf('?') !== -1 ? '&r' : '?') + start,
        true
      )
      req.send(null)
    }
  }, [active])

  return (
    <StepWrapper>
      <Flex>
        <StepTitle>{data.name}</StepTitle>
        {active && <Active />}
      </Flex>
      <ProgressBar progress={progress} color={preset && preset.color} />
      <Label>{speed} Mbit/s</Label>
      <Label>{remaining} sec</Label>
      {preset && <Label>Sufficient for: {preset.name}</Label>}
    </StepWrapper>
  )
}

function App() {
  const [isStarted, setIsStarted] = useState(false)
  const [current, setCurrent] = useState(0)
  const [size, setSize] = useState(100)

  const start = (size) => {
    setSize(size)
    setIsStarted(true)
  }

  return (
    <div className="App">
      <header className="App-header">
        {isStarted ? (
          <Wrapper>
            {steps.map((s, i) => (
              <Step
                key={i}
                active={i === current}
                data={s}
                onDone={() => setCurrent(current + 1)}
                size={size}
              />
            ))}
          </Wrapper>
        ) : (
          <>
            <Button onClick={() => start(100)}>Start test (100mb)</Button>
            <ButtonFast onClick={() => start(10)}>
              Start fast test (10mb)
            </ButtonFast>
          </>
        )}
      </header>
    </div>
  )
}

export default App
